<template>
  <div class="stress headmaster_box">
    <div class="class_title_box">
      <div>
        <p>重读申请</p>
        <div class="line"></div>
      </div>
      <span @click="dialogVisible = true"> 申请 </span>
    </div>
    <div class="bodybox">
      <div class="nolist" v-show="list.length == 0">
        <img src="../../assets/img/weixuanze@2x.png" alt="" />
        <p>您还未选择科目 请先选择科目</p>
      </div>
      <div class="list" v-show="list.length > 0">
        <div class="li" v-for="item in list" :key="item.createtime">
          <div>{{ item.name }}</div>
          <div>
            {{
              item.audit_time
                ? `通过时间:${item.audit_time}`
                : `申请时间:${item.createtime}`
            }}
          </div>
          <div>
            <li v-show="item.audit_status == 0">
              <img src="../../assets/img/tijiao@2x.png" alt="" />
              <span>待审核</span>
            </li>
            <li v-show="item.audit_status == 2">
              <img src="../../assets/img/tonngguo@2x.png" alt="" />
              <span>已通过</span>
            </li>
            <li v-show="item.audit_status == 1">
              <span
                @click="$emit('call', {})"
                style="
                  color: #3f7dff;
                  cursor: pointer;
                  padding-right: 22px;
                  border-right: 1px solid #bdbdbd;
                  margin-right: 22px;
                "
                >联系班主任</span
              >
              <img src="../../assets/img/weitongguo@2x.png" alt="" />
              <span>未通过</span>
            </li>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="dialogVisible"
      class="mask"
      @click="dialogVisible = false"
    ></div>
    <div v-show="dialogVisible" class="maskbox">
      <div class="tit">
        重读申请信息
        <img
          @click="dialogVisible = false"
          class="titimg"
          src="../../assets/img/classclose.png"
          alt=""
        />
      </div>
      <div class="stressbox">
        <h3>选择项目</h3>
        <div class="select">
          <el-select
            @change="selectchange"
            v-model="select.value"
            placeholder="请选择"
          >
            <el-option
              v-for="item in select.options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <h3>课程信息</h3>
        <div class="classlist">
          <div class="t">
            <div class="l">课程名称</div>
            <div class="r">剩余重读次数</div>
          </div>
          <div class="b" v-for="item in stresslist" :key="item.order_id">
            <div class="l">{{ item.course_name }}</div>
            <div class="r">{{ item.user_restudy_number }}</div>
          </div>
        </div>
        <p
          v-show="stresslist.length == 0 && select.value != ''"
          style="
            font-size: 16px;
            font-weight: 400;
            margin-top: 10px;
            color: #e62e17;
          "
        >
          您所选的项目下无报名的课程 没有重读服务
        </p>
        <div class="call" v-show="stresslist.length > 0 && !showform">
          您当前所选的课程暂无重读服务，如有问题请咨询<span
            @click="$emit('call', {})"
            >班主任</span
          >
        </div>
        <div v-show="showform">
          <h3>上传成绩图片</h3>
          <div class="uploadimg">
            <el-upload
              accept="image/*"
              :headers="header"
              :action="url.apiUrl + '/api/common/upload'"
              class="avatar-uploader"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <div v-else class="up">
                <img src="../../assets/img/shangchuan@2x.png" alt="" />
                点击上传
              </div>
            </el-upload>
          </div>
          <h3 class="red">填写申请理由</h3>
          <div class="desc">
            <el-input
              type="textarea"
              placeholder="请填写申请理由（不少于10个字）"
              v-model="textarea"
              maxlength="50"
              minlength="10"
              show-word-limit
            >
            </el-input>
          </div>
          <div class="buton" @click="restudy">
            <span>提交审核</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/api";
export default {
  name: "Stress",
  data() {
    return {
      header: {
        token: localStorage.getItem("token"),
      },
      list: [],
      showform: false,
      stresslist: [],
      apiurl: "",
      imgapi: "",
      imageUrl: "",
      textarea: "",
      dialogVisible: false,
      select: {
        options: [],
        value: "",
      },
    };
  },
  created() {
    api.restudy_apply_list().then((res) => {
      // console.log(res);
      this.list = res.data.data.list;
    });
    api.category_list().then((res) => {
      let a = [];
      res.data.data.list.forEach((e) => {
        a = [...a, ...e.children];
      });
      this.select.options = a;
    });
    // console.log(this.url);
  },
  methods: {
    restudy() {
      if (this.textarea.length < 10) {
        this.$message.error("申请理由不得少于10个字！");
      } else {
        api
          .restudy_course_add({
            course_category_id: this.select.value,
            // data_json: JSON.stringify(this.stresslist),
            score_image: this.imgapi,
            reason: this.textarea,
          })
          .then((res) => {
            if (res.data.code == 1) {
              this.$message.success(res.data.msg);
              api.restudy_apply_list().then((res) => {
                // console.log(res);
                this.list = res.data.data.list;
              });
            } else {
              this.$message.error(res.data.msg);
            }
            this.dialogVisible = false;
          });
      }
    },
    selectchange(e) {
      // console.log(e)
      api.restudy_course_list({ course_category_id: e }).then((res) => {
        this.stresslist = res.data.data.list;
        this.stresslist.forEach((e) => {
          if (e.user_restudy_number) {
            this.showform = true;
            return;
          } else {
            this.showform = false;
          }
        });
      });
    },
    handleAvatarSuccess(res, file) {
      // console.log(res);
      // console.log(file);
      this.imgapi = res.data.url;
      this.imageUrl = res.data.fullurl;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是图片格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
      // return isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  .li {
    display: flex;
    align-items: center;
    padding: 18px 48px 18px 24px;
    border-bottom: 1px solid #f2f2f2;
    div {
      width: 33.3%;
      text-align: left;
      font-size: 18px;
      font-weight: 400;
      color: #010101;
    }
    div:nth-child(2) {
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      color: #999999;
    }
    div:last-child {
      li {
        display: flex;
        box-sizing: border-box;
        padding: 0 15px;
        justify-content: flex-end;
        font-size: 16px;
        font-weight: 400;
        color: #515151;
        align-items: center;
        img {
          width: 24px;
          margin-right: 5px;
        }
      }
    }
  }
}
.buton {
  display: flex;
  margin-top: 35px;
  align-items: center;
  justify-content: flex-end;
  span {
    cursor: pointer;
    width: 114px;
    height: 30px;
    background: #3f7dff;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
  }
}
.red {
  position: relative;
  padding-left: 10px;
}
.red::after {
  content: "*";
  color: #d81e06;
  font-size: 16px;
  font-weight: bold;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}
::v-deep .desc textarea {
  height: 81px;
}
.uploadimg {
  border-radius: 4px;
  border: 1px solid #cccccc;
  .up {
    width: 600px;
    display: flex;
    justify-content: center;
    padding: 27px 0 20px;
    font-size: 16px;
    color: #666666;
    align-items: center;
    font-weight: 400;
    img {
      width: 35px;
      margin-right: 10px;
    }
  }
}
.uploadimg .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.uploadimg .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.uploadimg .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.stress {
  position: relative;
  .mask {
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.4);
    position: absolute;
    top: 0;
    left: 0;
  }
  .maskbox {
    width: 841px;
    height: 515px;
    background: #fff;
    position: absolute;
    overflow-y: auto;
    border-radius: 8px;
    top: 62px;
    left: 50%;
    transform: translateX(-50%);
    .tit {
      padding: 21px 0 12px;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      color: #3f7dff;
      border-bottom: 1px solid #f2f2f2;
      line-height: 18px;
      position: relative;
      .titimg {
        width: 17px;
        height: 17px;
        position: absolute;
        top: 50%;
        right: 50px;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
    .stressbox {
      padding: 0 120px 26px;
      background: #fff;
      h3 {
        font-size: 16px;
        font-weight: 400;
        color: #010101;
        line-height: 18px;
        margin-bottom: 10px;
        margin-top: 26px;
      }
    }
    .classlist {
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid #e3e3e3;
      .t {
        background: #3f7dff;
        color: #fff;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: center;
        .l {
          border-right: 1px solid #e3e3e3;
        }
      }
      .b:last-child {
        border: none;
      }
      .b {
        color: #020202;
        font-size: 14px;
        display: flex;
        align-items: center;
        line-height: 18px;
        border-bottom: 1px solid #e3e3e3;
        .l {
          border-right: 1px solid #e3e3e3;
        }
      }
      .l {
        width: 430px;
        padding: 12px;
        text-align: center;
      }
      .r {
        text-align: center;
        width: 170px;
        padding: 12px;
      }
    }
  }
}

.call {
  margin-top: 10px;
  display: flex;
  align-items: center;
  color: #e62e17;
  font-size: 14px;
  font-weight: 400;
  span {
    color: #3f7dff;
    position: relative;
    cursor: pointer;
  }
  span::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: #3f7dff;
    position: absolute;
    bottom: -5px;
    left: 0;
  }
}
.maskbox::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

/*正常情况下滑块的样式*/
.maskbox::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
}

/*鼠标悬浮在该类指向的控件上时滑块的样式*/
.maskbox::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
}

/*鼠标悬浮在滑块上时滑块的样式*/
.maskbox::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
}

/*正常时候的主干部分*/
.maskbox::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0);
  background-color: #eeeeee;
}

/*鼠标悬浮在滚动条上的主干部分*/
.maskbox::-webkit-scrollbar-track:hover {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.4);
  background-color: rgba(0, 0, 0, 0.01);
}
.bodybox {
  width: 950px;
  min-height: 558px;
  margin-top: 10px;
  background: #fff;
  .nolist img {
    display: block;
    width: 240px;
    height: 179px;
    padding-top: 171px;
    margin: 0 auto;
  }
  .nolist p {
    padding-top: 21px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #4d4d4d;
  }
}
.class_title_box {
  width: 950px;
  position: relative;
  height: 62px;
  background: #fff;
  span {
    width: 74px;
    height: 30px;
    background: #3f7dff;
    border-radius: 15px;
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 36px;
    transform: translateY(-50%);
  }

  div {
    // width: 100%;
    display: inline-block;
    height: 62px;
    position: relative;
    margin-left: 29px;

    p {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #3e7eff;
      line-height: 62px;
      text-align: center;
    }

    .line {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 60px;
      height: 3px;
      background: #3e7eff;
    }
  }
}
</style>