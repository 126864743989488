/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

// 用户名验证
export function validUserName(name) {
  return /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})$/.test(name.trim())
}

// 用户密码验证
export function validUserPassword(password) {
  return /^[a-zA-Z]([-_a-zA-Z0-9]{7,15})$/.test(password.trim())
  // return /^(?=.[a-z])(?=.[A-Z])(?=.*\d)[\s\S]{8,16}$/.test(password.trim())
}

// 手机号正则
export function checkPhone(phone) {
  return /^1[3456789]\d{9}$/.test(phone)
}

// 身份证验证
export function idcard(card) {
  return /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(card)
}


//时间戳转时间
export function getLocalTime(str) {
  var n = parseInt(str) * 1000;
  var D = new Date(n);
  var year = D.getFullYear();//四位数年份

  var month = D.getMonth() + 1;//月份(0-11),0为一月份
  month = month < 10 ? ('0' + month) : month;

  var day = D.getDate();//月的某一天(1-31)
  day = day < 10 ? ('0' + day) : day;

  var hours = D.getHours();//小时(0-23)
  hours = hours < 10 ? ('0' + hours) : hours;

  var minutes = D.getMinutes();//分钟(0-59)
  minutes = minutes < 10 ? ('0' + minutes) : minutes;

  // var seconds = D.getSeconds();//秒(0-59)
  // seconds = seconds<10?('0'+seconds):seconds;
  // var week = D.getDay();//周几(0-6),0为周日
  // var weekArr = ['周日','周一','周二','周三','周四','周五','周六'];

  var now_time = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
  return now_time;
}